const isTest = process.env.NODE_ENV === 'development';

export default {
	type: 'client',
	api: {
		version: 'v0.0.1',
	},
	version: 'v0.9.149',
	isTest,
	useSentry: !isTest,
};
